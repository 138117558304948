import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import {
  AmplifyProvider,
  Authenticator,
  Button,
  Flex,
  Text,
  View,
} from "@aws-amplify/ui-react";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import {
  ListDeploymentsByZoneByDateQuery,
  ListDeploymentsByZoneByDateQueryVariables,
  ModelSortDirection,
} from "./API";
import * as queries from "./graphql/queries";

import "@aws-amplify/ui-react/styles.css";
//import theme from "./theme";

//import aws_exports from "./aws-exports";
import awsConfig from "./aws-exports";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, productionRedirectSignIn] =
  awsConfig.oauth.redirectSignIn.split(",");

const [localRedirectSignOut, productionRedirectSignOut] =
  awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
};
console.log(JSON.stringify(updatedAwsConfig));

Amplify.configure(updatedAwsConfig);
//Amplify.configure(aws_exports);

const App = () => {
  const [deployments, setDeployments] = useState<
    ListDeploymentsByZoneByDateQuery | undefined
  >(undefined);

  useEffect(() => {
    fetchDeployments();
  }, []);

  async function fetchDeployments() {
    const variables: ListDeploymentsByZoneByDateQueryVariables = {
      zone: "prod",
      sortDirection: ModelSortDirection.DESC,
    };
    try {
      const response = (await API.graphql(
        graphqlOperation(
          queries.listDeploymentsByZoneByDate,
          variables,
          GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        )
      )) as {
        data: ListDeploymentsByZoneByDateQuery;
      };
      const deployments = response.data;
      console.log(JSON.stringify(deployments));
      setDeployments(deployments);
    } catch (error) {
      console.log(error);
    }
  }
  //theme={theme}
  return (
    <AmplifyProvider>
      <Authenticator>
        {({ signOut, user }) => (
          <>
            <Flex
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="flex-start"
              wrap="nowrap"
              gap="1rem"
              textAlign="center"
            >
              {user && (
                <div className="text-xl font-semibold text-gray-900">
                  <View width="100%">
                    <Text>Hello {user.attributes?.email}</Text>
                  </View>
                </div>
              )}
            </Flex>
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-xl font-semibold text-gray-900">
                    Deployments
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    A list of the most recent deployments in reverse
                    chronological order.
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <Button onClick={signOut}>
                    <Text>Sign Out</Text>
                  </Button>
                </div>
              </div>
              <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        {deployments &&
                          deployments.listDeploymentsByZoneByDate && (
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                  Zone
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  ID
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  System
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Version
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Deployed
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Commit
                                </th>
                              </tr>
                            </thead>
                          )}
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {deployments &&
                            deployments.listDeploymentsByZoneByDate &&
                            deployments.listDeploymentsByZoneByDate?.items?.map(
                              (deployment) => (
                                <tr key={deployment?.id}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {deployment?.zone}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{`${deployment?.id.substring(
                                    0,
                                    6
                                  )}...`}</td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <a
                                      className="text-indigo-600 hover:text-indigo-900"
                                      href={`https://github.com/adzerk/${deployment?.systemName}`}
                                    >
                                      {deployment?.systemName}
                                    </a>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {deployment?.version}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {deployment?.deployedAt &&
                                      new Date(
                                        deployment?.deployedAt
                                      ).toLocaleDateString() +
                                        " " +
                                        new Date(
                                          deployment?.deployedAt
                                        ).toLocaleTimeString()}
                                  </td>
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                                    <a
                                      className="text-indigo-600 hover:text-indigo-900"
                                      href={`https://github.com/adzerk/${deployment?.systemName}/commit/${deployment?.commitSha}`}
                                    >
                                      commit
                                    </a>
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Authenticator>
    </AmplifyProvider>
  );
};

export default App;
