/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDeployment = /* GraphQL */ `
  query GetDeployment($id: ID!) {
    getDeployment(id: $id) {
      id
      systemName
      version
      deployedAt
      awsAccountId
      commitSha
      team
      zone
      createdAt
      updatedAt
    }
  }
`;
export const listDeployments = /* GraphQL */ `
  query ListDeployments(
    $filter: ModelDeploymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeployments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        systemName
        version
        deployedAt
        awsAccountId
        commitSha
        team
        zone
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDeploymentsByZoneByDate = /* GraphQL */ `
  query ListDeploymentsByZoneByDate(
    $zone: String!
    $deployedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeploymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeploymentsByZoneByDate(
      zone: $zone
      deployedAt: $deployedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        systemName
        version
        deployedAt
        awsAccountId
        commitSha
        team
        zone
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
