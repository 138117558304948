/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateDeploymentInput = {
  id?: string | null,
  systemName: string,
  version: string,
  deployedAt: string,
  awsAccountId?: string | null,
  commitSha?: string | null,
  team?: string | null,
  zone?: string | null,
};

export type ModelDeploymentConditionInput = {
  systemName?: ModelStringInput | null,
  version?: ModelStringInput | null,
  deployedAt?: ModelStringInput | null,
  awsAccountId?: ModelStringInput | null,
  commitSha?: ModelStringInput | null,
  team?: ModelStringInput | null,
  zone?: ModelStringInput | null,
  and?: Array< ModelDeploymentConditionInput | null > | null,
  or?: Array< ModelDeploymentConditionInput | null > | null,
  not?: ModelDeploymentConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type Deployment = {
  __typename: "Deployment",
  id: string,
  systemName: string,
  version: string,
  deployedAt: string,
  awsAccountId?: string | null,
  commitSha?: string | null,
  team?: string | null,
  zone?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDeploymentInput = {
  id: string,
  systemName?: string | null,
  version?: string | null,
  deployedAt?: string | null,
  awsAccountId?: string | null,
  commitSha?: string | null,
  team?: string | null,
  zone?: string | null,
};

export type DeleteDeploymentInput = {
  id: string,
};

export type ModelDeploymentFilterInput = {
  id?: ModelIDInput | null,
  systemName?: ModelStringInput | null,
  version?: ModelStringInput | null,
  deployedAt?: ModelStringInput | null,
  awsAccountId?: ModelStringInput | null,
  commitSha?: ModelStringInput | null,
  team?: ModelStringInput | null,
  zone?: ModelStringInput | null,
  and?: Array< ModelDeploymentFilterInput | null > | null,
  or?: Array< ModelDeploymentFilterInput | null > | null,
  not?: ModelDeploymentFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelDeploymentConnection = {
  __typename: "ModelDeploymentConnection",
  items:  Array<Deployment | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type CreateDeploymentMutationVariables = {
  input: CreateDeploymentInput,
  condition?: ModelDeploymentConditionInput | null,
};

export type CreateDeploymentMutation = {
  createDeployment?:  {
    __typename: "Deployment",
    id: string,
    systemName: string,
    version: string,
    deployedAt: string,
    awsAccountId?: string | null,
    commitSha?: string | null,
    team?: string | null,
    zone?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDeploymentMutationVariables = {
  input: UpdateDeploymentInput,
  condition?: ModelDeploymentConditionInput | null,
};

export type UpdateDeploymentMutation = {
  updateDeployment?:  {
    __typename: "Deployment",
    id: string,
    systemName: string,
    version: string,
    deployedAt: string,
    awsAccountId?: string | null,
    commitSha?: string | null,
    team?: string | null,
    zone?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDeploymentMutationVariables = {
  input: DeleteDeploymentInput,
  condition?: ModelDeploymentConditionInput | null,
};

export type DeleteDeploymentMutation = {
  deleteDeployment?:  {
    __typename: "Deployment",
    id: string,
    systemName: string,
    version: string,
    deployedAt: string,
    awsAccountId?: string | null,
    commitSha?: string | null,
    team?: string | null,
    zone?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetDeploymentQueryVariables = {
  id: string,
};

export type GetDeploymentQuery = {
  getDeployment?:  {
    __typename: "Deployment",
    id: string,
    systemName: string,
    version: string,
    deployedAt: string,
    awsAccountId?: string | null,
    commitSha?: string | null,
    team?: string | null,
    zone?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDeploymentsQueryVariables = {
  filter?: ModelDeploymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDeploymentsQuery = {
  listDeployments?:  {
    __typename: "ModelDeploymentConnection",
    items:  Array< {
      __typename: "Deployment",
      id: string,
      systemName: string,
      version: string,
      deployedAt: string,
      awsAccountId?: string | null,
      commitSha?: string | null,
      team?: string | null,
      zone?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListDeploymentsByZoneByDateQueryVariables = {
  zone: string,
  deployedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDeploymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDeploymentsByZoneByDateQuery = {
  listDeploymentsByZoneByDate?:  {
    __typename: "ModelDeploymentConnection",
    items:  Array< {
      __typename: "Deployment",
      id: string,
      systemName: string,
      version: string,
      deployedAt: string,
      awsAccountId?: string | null,
      commitSha?: string | null,
      team?: string | null,
      zone?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateDeploymentSubscription = {
  onCreateDeployment?:  {
    __typename: "Deployment",
    id: string,
    systemName: string,
    version: string,
    deployedAt: string,
    awsAccountId?: string | null,
    commitSha?: string | null,
    team?: string | null,
    zone?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDeploymentSubscription = {
  onUpdateDeployment?:  {
    __typename: "Deployment",
    id: string,
    systemName: string,
    version: string,
    deployedAt: string,
    awsAccountId?: string | null,
    commitSha?: string | null,
    team?: string | null,
    zone?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDeploymentSubscription = {
  onDeleteDeployment?:  {
    __typename: "Deployment",
    id: string,
    systemName: string,
    version: string,
    deployedAt: string,
    awsAccountId?: string | null,
    commitSha?: string | null,
    team?: string | null,
    zone?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
